import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import { FaCircle } from 'react-icons/fa'
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components'

let StyledImg = styled(props => <GatsbyImage {...props}/>)`
  perspective: 1500px;
  perspective-origin: left center;
  overflow: visible !important;
  picture, img {
    transform: rotateY(-20deg) rotateX(15deg) translateX(-10px) !important;
    box-shadow: 25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2);
    border-radius: .625rem;
    transition: 1s !important;
    &:hover {
      transform: rotateY(-15deg) rotateX(10deg) translateX(-10px) !important;
    }
  }
`

const Title1 = styled.h2`
  font-family: 'Raleway';
  font-size: 2rem;
`

let Benefit = ({title, content}) => (
  <div className="d-flex mb-4">
    <FaCircle size={30} className="primary"/>
    <div className="ml-3">
      <h4>{title}</h4>
      <p className="m-0 text-muted">{content}</p>
    </div>
  </div>
)

let Benefits = ({data}) => (
  <Container className="py-5">
    <Row className="d-flex align-items-center">
      <Col md="6" className="mb-5">
        <div className="mb-5 primary">
          <Title1>Возможности сервиса</Title1>
        </div>
        <Benefit title="Отслеживание изменений" content="DEBTTRACKER отслеживает изменения сумм исполнительных производств и&nbsp;хранит&nbsp;историю"/>
        <Benefit title="Контроль ошибок" content="DEBTTRACKER контроллирует корректность списания сумм приставами и&nbsp;уведомляет о&nbsp;нарушениях"/>
        <Benefit title="Формирования документов" content="DEBTTRACKER автоматически формирует документы на&nbsp;основе настраиваемых шаблонов"/>
      </Col>
      <Col md="6" className="mb-5">
        <StyledImg image={data.file.childImageSharp.gatsbyImageData} objectFit="contain" objectPosition="50% 50%"/>
      </Col>
    </Row>
  </Container>
)

const BenefitsQuery = () => (
  <StaticQuery
    query={graphql`
      query BenefitsQuery {
        file(relativePath: {eq: "sample.png"}) {
          id
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
      }
    `}
    render={data => (
      <Benefits data={data}/>
    )}
  />
)

export default BenefitsQuery
