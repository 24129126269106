import React from 'react'
import {Container, Col, Row} from 'reactstrap'
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';

let StyledFeature = styled.div`
  box-shadow:
  0 2.1px 2.7px rgba(0, 0, 0, 0.009),
  0 5.4px 6.9px rgba(0, 0, 0, 0.013),
  0 11px 14.2px rgba(0, 0, 0, 0.017),
  0 22.6px 29.2px rgba(0, 0, 0, 0.021),
  0 62px 80px rgba(0, 0, 0, 0.03)
  ;
  border-radius: 1rem;
  transition-duration: 0.25s;
  &:hover {
    box-shadow:
    0 2.1px 2.7px rgba(0, 0, 0, 0.022),
    0 5.4px 6.9px rgba(0, 0, 0, 0.031),
    0 11px 14.2px rgba(0, 0, 0, 0.039),
    0 22.6px 29.2px rgba(0, 0, 0, 0.048),
    0 62px 80px rgba(0, 0, 0, 0.07)
;
  }
`

let Feature = ({title, description, img}) => (
  <Col md={4} className="mb-3">
    <StyledFeature>
      <GatsbyImage className="b-r-1" image={img}/>
      <div className="p-3">
        <h5>{title}</h5>
        <p>{description}</p>
      </div>
    </StyledFeature>
  </Col>
)

let HomeFeatures = () => {
  const data = useStaticQuery(graphql`
    fragment defaultImage on File {
      childImageSharp {
        gatsbyImageData(width: 400, height: 250)
      }
    }
    query featuresQuery {
      slide1: file(relativePath: { eq: "features-1.png" }) {
        ...defaultImage
      }
      slide2: file(relativePath: { eq: "features-2.png" }) {
        ...defaultImage
      }
      slide3: file(relativePath: { eq: "features-3.png" }) {
        ...defaultImage
      }
    }
  `)
  return (
    <Container className="py-5">
      <Row>
        <Feature
          title="Индивидуальный подход"
          description="Для нас дорог и ценен каждый клиент, поэтому мы настроены на поиск оптимальных решений для удовлетворения тех задач, с которыми Вы обратились к нам."
          img={data.slide1.childImageSharp.gatsbyImageData}
        />
        <Feature
          title="Поддержка клиентов"
          description="Мы стараемся делать так, чтобы наш сервис был интуитивно понятным и у Вас не возникало никаких трудностей, 
          но в случае если у вас возникнут проблемы, то мы обязательно поможем их решить."
          img={data.slide2.childImageSharp.gatsbyImageData}
        />
        <Feature
          title="Кастомизация сервиса"
          description="Наши специалисты всегда готовы добавить нужную форму документа или даже
          доработать логику работы сервиса. Мы всегда рады слышать предложения по улучшению сервиса.
          "
          img={data.slide3.childImageSharp.gatsbyImageData}
        />
      </Row>
    </Container>
  )
}

export default HomeFeatures
