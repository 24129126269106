import React from "react"

import { Container, Row, Col } from 'reactstrap'
import Link from '../components/link'
import Button from '../components/btn'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaGithub, FaBolt, FaHome, FaWrench } from 'react-icons/fa'
import Form from '../components/form'
import SliderQuery from '../components/slider'
import Box from '../components/box'
import Hr from '../components/hr'
import Benefits from '../components/benefits'
import styled from 'styled-components'
import HomeFeatures from '../components/homeFeatures'
import CircleHr from "../components/circleHr"
import InfoBlock from "../components/infoBlock"

let StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`

const Title = styled.h3`
  font-family: 'Raleway';
  font-size: 2rem;
  color: ${props => props.theme.primary};
`

let Service = ({title, Icon = FaHome}) => (
  <Col xs={12} md={4} className="mb-3">
    <Link to="/">
      <Box>
        <Icon size={30}/>
        <h4 className="mt-3">{title}</h4>
      </Box>
    </Link>
  </Col>
)

/*

<Container className="py-5">
  <h2 className="text-center mb-4">Services</h2>
  <Row>
    <Service title="Websites"/>
    <Service title="SEO" Icon={FaBolt}/>
    <Service title="API" Icon={FaWrench}/>
  </Row>
</Container>
<div className="text-center py-5">
  <Button to="https://github.com/jeremylynch/gatsby-strapi-starter" className="btn btn-primary btn-lg">
    <FaGithub className="mr-1"/>
    View on Github
  </Button>
</div>

*/

let Index = () => (
  <Layout>
    <SEO title="DEBTTRACKER.RU" />
    <SliderQuery/>
    <Container className="pt-4">
      <div className="text-center m-w-700">
        <InfoBlock>Наш опыт и опыт работы наших партнеров в&nbsp;области взыскания 
          долгов с&nbsp;физических лиц в&nbsp;2020&nbsp;году был воплощен в&nbsp;сервисе DEBTTRACKER.RU
          <CircleHr/>
          Инструменты и алгоритмы сервиса в&nbsp;качестве отдельных модулей использовались уже в&nbsp;течении многих&nbsp;лет и&nbsp;подтвердили свою эффективность
        </InfoBlock>
      </div>
    </Container>
    
    <StyledBackground>
      <Benefits/>
      <div className="py-5">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <Box style={{textAlign: 'left'}}>
                <Title className="text-center">Заказать демонстрацию</Title>
                <Hr/>
                <Form name="demo-form"/>
              </Box>
            </Col>
          </Row>
        </Container>
      </div>
    </StyledBackground>
    <HomeFeatures/>
  </Layout>
)

export default Index
