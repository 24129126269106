import React from 'react'
import styled from "styled-components"

const BoxStyling = styled.div`
  border-radius: 0.5rem;
  padding: 2rem;
  transition-duration: 0.3s;
  text-align: center;
  color: ${props => props.theme.primary};
  font-size: 1.7em;
  margin-top: 3rem;
  margin-bottom: 3rem;
`

let InfoBlock = ({children, ...opts}) => (
  <BoxStyling {...opts}>
    {children}
  </BoxStyling>
)

export default InfoBlock
