import React from 'react'
import styled from "styled-components"

const BoxStyling = styled.div`
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow:
  0 2.1px 2.7px rgba(0, 0, 0, 0.009),
  0 5.4px 6.9px rgba(0, 0, 0, 0.013),
  0 11px 14.2px rgba(0, 0, 0, 0.017),
  0 22.6px 29.2px rgba(0, 0, 0, 0.021),
  0 62px 80px rgba(0, 0, 0, 0.03)
  ;
  transition-duration: 0.3s;
  text-align: center;
  &:hover {
    box-shadow:
    0 2.1px 2.7px rgba(0, 0, 0, 0.022),
    0 5.4px 6.9px rgba(0, 0, 0, 0.031),
    0 11px 14.2px rgba(0, 0, 0, 0.039),
    0 22.6px 29.2px rgba(0, 0, 0, 0.048),
    0 62px 80px rgba(0, 0, 0, 0.07)
;
  }
`

let Box = ({children, ...opts}) => (
  <BoxStyling {...opts}>
    {children}
  </BoxStyling>
)

export default Box
